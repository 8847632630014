import React from "react";

export const Header = () => (
  <nav className="max-w-5xl w-full mx-auto flex flex-col lg:flex-row space-y-10 lg:space-y-0 items-center justify-between px-7 lg:px-0 py-10 lg:py-14">
    <img src={require("../images/logo.svg").default} />
    <div className="flex items-center">
      <span className="text-sm lg:text-base font-bold ">Reach Out To Us:</span>
      <a
        className="inline-flex justify-center items-center rounded-lg h-10 w-[180px] text-center text-black font-normal bg-[#18F8CD] ml-4"
        href="mailto:hello@nextlyte.com"
      >
        hello@nextlyte.com
      </a>
    </div>
  </nav>
);
