import React from "react";

export const Portfolio = () => (
  <div className="">
    <h4 className="mb-12 text-2xl font-bold text-center lg:text-4xl">
      Our Portfolios
    </h4>
    <div className="grid lg:grid-cols-2 h-[1200px] lg:h-[346px]">
      {portfolio.map((p) => (
        <div
          style={{ backgroundColor: p.bg_color }}
          className="relative h-full"
        >
          <img className="absolute bottom-0 right-0 z-0" src={p.bg_image} />
          <div className="relative z-10 flex flex-col items-start justify-center h-full bg-black px-7 lg:px-16 bg-opacity-20">
            <img src={p.logo} className="w-auto h-8" />
            <p className="mt-4 mb-4 text-xl lg:h-28 lg:mb-2">{p.description}</p>
            <div className="text-sm">
              {p.coming_soon ? (
                <span>Coming soon</span>
              ) : (
                <a href={p.link}>
                  <span className="underline">Visit website</span> &rarr;
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const portfolio = [
  {
    logo: require("../images/prepbook.svg").default,
    description:
      "An E-Learning platform to help African students be the very best they can be.",
    coming_soon: true,
    bg_color: "#CC52B0",
    bg_image: require("../images/freelancer.svg").default,
  },
  {
    logo: require("../images/larkbin.svg").default,
    description:
      "A One stop solution for managing payroll and other business needs.",
    coming_soon: true,
    bg_color: "#2FC55B",
    bg_image: require("../images/problem-solving.svg").default,
  },
];
