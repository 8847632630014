import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "../components/Header";
import { Portfolio } from "../components/Portfolio";

const App = () => {
  return (
    <div className="bg-[#030303] h-screen w-screen">
      <Helmet>
        <title>Nextlyte</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main className="min-h-[1024px] relative overflow-hidden max-w-[1500px] mx-auto  text-white flex flex-col items-stretch">
        <img
          className="absolute right-[-20%] z-0"
          src={require("../images/bg-right-ellipse.svg").default}
        />
        <Header />
        <section className="relative mt-20 mb-32 text-center lg:mb-0 px-7 lg:px-0">
          <img
            src={require("../images/bg-sphere.svg").default}
            className="absolute translate-x-1/2 right-1/2 -top-16"
          />
          <img
            src={require("../images/bg-green-ellipse.svg").default}
            className="absolute z-10 hidden -top-20 md:block"
          />
          <div className="relative z-10">
            <h2 className="font-monument text-5xl lg:text-[64px] leading-snug font-extrabold">
              Finding The Next Light
            </h2>
            <p className="text-lg lg:text-2xl font-light mt-6 max-w-[891px] mx-auto">
              We use our problem solving skills and deep understanding of
              <br />
              global startup space to build internet and technology companies
              globally.
            </p>
          </div>
        </section>
        <div className="relative z-10 mt-auto">
          <Portfolio />
        </div>
      </main>
    </div>
  );
};
export default App;
